header.banner {
	padding: 0 0 .5rem 0;
	z-index: 9999;
	background-color: $light-gray; 
	transition: all 0.5s;
	
	#topbar { background: $chartreuse; text-align:center; padding: 9px 0; font-size:20px;  }
	#topbar a { color: #fff;   }

	#menu-header-menu {
		@include media-breakpoint-up(md) { }
		li {
			padding: 0 .5em;
			&:last-child { padding-right: 0; }
			&:after {
				content: "|";
    			color: $brand-secondary;
			}
			a {
				color: $brand-secondary;
				font-size: 0.9rem;
				font-weight: 600;
				text-transform: uppercase;
				line-height: 30px;  /* to align header nave and phone */
				padding-right: 1em;
			}
		}
	}

	.nav-primary {
		transition: margin-top 0.1s;
		padding-top: 5px; 
		
		/*ul.nav > li > a { padding: 0.5rem 0 0.5rem 1rem; }  top level only */
		ul.nav > li > a:after { 
			padding-left: 1rem; color: #000; font-weight:400; 
			@include media-breakpoint-up(lg) {  content: "|"; }
		}	
		ul.nav > li.last > a:after { content:""; }		
		
		li {
			transition: border-color 0.2s ease;
			padding: 0;
			& > ul {
				display: none;
			}
			a {
				/* padding: .5rem 1.25rem; */
				padding: 0.5rem 0 0.5rem 1rem;
				display: block;
				/*color: $brand-primary;  */
				color: #000; 
				text-decoration: none;
				font-weight: 600;
				font-size: 1rem;
				&:hover { text-decoration: none; }
				.active { color: #000; }
			}
			&:hover {
				& > ul {
					display: block;
					position: absolute;
				}
				.sub-menu a:hover { background-color: rgba(0,0,0,.1); } 
			}
			&.last a { padding-right: 0; }				
			&:last-child a { padding-right: 0; }			
			&.mobile {
				display:none;
			}				
			.sub-menu {
				margin: 0;
				padding: 15px 0;
				z-index: 9999;
				background: #fff; 
				li {
					list-style-type: none;
					position: relative;
					padding: 0 20px; 
					
					.sub-menu {
						width: 100%;
						position: absolute;
						left: 100%;
						top: 0;
					}
				}
				a { padding: 0.5rem; }
			}
		}
		.current-menu-item a { color: $brand-secondary; }
	}
}



/*  media queries and responsive  */
.fixed-header-logo { display: none; }
.navbar-toggle { display: none; }

@media (min-width: 993px) and (max-width:1199px) {  /* prevent wrapping at 1024 */
	header.banner {
		.nav-primary li a { }
	}
}

@media (max-width: 992px) {
	header.banner {
		.navbar-toggle {
			display: block;
			padding: 0.75rem;
			float: right;
			background: transparent;
			border: 0;
			.icon-bar { background: #082259; }
		}
		.navbar-toggle.offcanvas-toggle.js-offcanvas-has-events {
			position: absolute;
			right: 0;	top: 10px;
		}
		.navbar-offcanvas.in {
			left: -250px !important;
		}
		.nav-primary li a {  
			text-transform: capitalize !important;
		}	
	}
}

@media (min-width: 768px) and (max-width: 992px) { header.banner div.phone { float: none; width:100%;  padding-left: 20px; }  }
@media (max-width: 768px) {
	.nav-primary .navbar-offcanvas.in {
		left: 300px !important;
	}
	.main-nav .navbar-offcanvas.offcanvas-transform.in {
		transform: translateX(250px) !important;
	}
}


