footer.site-footer {
	background: $cream;
	padding: 3rem 0;
	font-size: 15px; 
	.yin { margin-bottom:20px; }
	div.label { font-weight:700; text-transform:uppercase; }
	.col-12.widget { margin-bottom: 1rem; }
	.col-12.widget span { color: $orange; }
	.copyright { padding-bottom: 0; padding-top: 1rem; font-size: 14px; border-top: 2px solid $light-gray; }
		
	ul.menu {
		list-style-type: none;
		padding: 0;
		li { display: inline; padding-bottom:.5rem; margin: 5px 8px; }
		a { text-decoration:none; color:#000; font-weight: 600;}
		a:hover { text-decoration: underline; }
	}	
	ul.menu-social { 
		padding:0;
		li {
    		display: inline-block; text-align: center; 
			width: 44px; height: 40px; line-height: 40px; 
    		margin: 15px 4px 0; background: #000; border-radius: 8px;
			a { color:#fff5e2; font-size:30px; }			
		}
	} 
}

	
@media (max-width: 992px) {}
@media (max-width: 768px) {}
@media (max-width: 544px) {}